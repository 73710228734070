<template>
  <v-main
    id="pages"
    :class="$vuetify.theme.light ? undefined : 'grey lighten-3'"
  >
    <v-responsive
      :style="styles"
      min-height="100vh"
      class="d-flex align-center"
    >
      <router-view />
    </v-responsive>
  </v-main>
</template>

<script>
export default {
  name: "PagesCoreView",
  computed: {
    styles() {
      const paddingTop = this.$vuetify.breakpoint.mdAndUp ? 150 : 100
      return {
        padding: `${paddingTop}px 0 150px 0`,
      }
    },
  },
}
</script>
